div
<template>
  <div class="direction center mission_page bgSea">
    <div class="top">
      <div class="title">Task</div>
    </div>
    <div class="tab">
      <div @click="changeTab(index)" v-for="(item, index) in tab" :key="index" :class="{ active: activeTab === index }"
        :style="{ color: activeTab === index ? 'black' : '' }">
        {{ item }}
      </div>
    </div>
    <div style="margin-top: 1rem">
      <div v-if="activeTab === 0">
        <div class="list">
          <div class="listItem" v-for="(item, index) in dailyRemoteList" :key="index">
            <div class="itemLeft">
              <img class="itemImg" :src="item.url" alt="" />
              <div class="itemTitle">{{ item.title }}</div>
            </div>
            <div class="itemRight">
              <div class="itemCoin">
                <img :src="imgs.coin" alt="" />+{{ item.award }}
              </div>
              <div class="receive" @click="handleBtnclick2(item)" :class="{ finish: item.status == 2 }">
                {{ statusList[item.status] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 1">
        <div class="list">
          <div class="listItem" v-for="(item, index) in achievementListRight" :key="index">
            <div class="itemLeft">
              <div class="itemImg">
                <img :src="item.url" alt="" />
              </div>
              <div class="itemInfo">
                <div class="itemSend">{{ item?.title }}</div>
                <div class="itemWidth">
                  <div class="procWidth" :style="{
                    width:
                      item?.target?.completion_num >= item?.target?.number
                        ? '100%'
                        : (item?.target?.completion_num /
                          item?.target?.number) *
                        100 +
                        '%',
                  }"></div>
                  <div class="proc">
                    {{
                      MinCount(
                        item?.target?.completion_num,
                        item?.target?.number
                      )
                    }}/{{ item?.target?.number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="itemRight">
              <div class="itemCoin">
                <img :src="imgs.coin" alt="" />+{{ item.award }}
              </div>
              <div class="receive" @click="handleBtnClickRight(item)" :class="{ finish: item.status == 2 }">
                {{ statusListRight[item.status] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-toast v-model:show="show" style="padding: 0">
      <van-toast v-model:show="isReceiveShow" :icon="imgs.coin" :message="`+ ${awardCoin}`" style="padding: 0">
      </van-toast>
    </van-toast>
  </div>
</template>

<script>
import flag from 'vue-country-flag-next'
import { jumpTaskLink, getCurrentTaskStatus } from '@/config/common/dic'
import { getUserInitInfo, achieveListRef } from '@/config/common/total'
import { showToast } from 'vant'
import $pay from '@/config/common/pay'
export default {
  components: { flag },
  data() {
    return {
      time: 30 * 60 * 1000,
      teskClose: false,
      show: false,
      sendBottleNum: 17,
      taskNum: 0,
      imgs: this.$store.state.sourceMsg,
      statusList: ['Go to', 'Receive', 'Finish', 'Check'],
      statusListRight: ['Go to', 'Receive', 'Finish'],
      achievementListRight: [],
      activeTab: 0,
      tab: ['Social', 'Achievement'],
      isReceiveShow: false,
      reqFlag: false,
      dailyList: [
        {
          id: 0,
          title: 'Join our TG channel',
          coin: 20,
          status: false,
          img: this.$store.state.sourceMsg.TG,
        },
        {
          id: 1,
          title: 'Join our TG group',
          coin: 20,
          status: false,
          img: this.$store.state.sourceMsg.group,
        },
        {
          id: 2,
          title: 'Join our TAPPPark',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.TAPPPark,
        },
        {
          id: 3,
          title: 'Authorization TG wallet',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.wallet1,
        },
        {
          id: 4,
          title: 'Throw the bottle',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.throwbottle,
        },
        {
          id: 5,
          title: 'Reply to bottle message',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.reply1,
        },
        {
          id: 6,
          title: 'Spend tokens',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.wallet2,
        },
        {
          id: 7,
          title: 'Open treasure chest',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.chest,
        },
        {
          id: 8,
          title: 'Pick up debris',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.debris,
        },
      ],
      achiList: [
        { count: 1, status: true },
        { count: 3, status: true },
        { count: 5, status: false },
        { count: 10, status: false },
        { count: 20, status: false },
        { count: 40, status: false },
        { count: 50, status: false },
        { count: 60, status: false },
      ],
      achievementList: [],
      dailyRemoteList: [],
      // progressList:[
      //     { id:0, num:50, img:this.$store.state.sourceMsg.box1, imgOpen:this.$store.state.sourceMsg.box1_open },
      //     { id:0, num:100, img:this.$store.state.sourceMsg.box1, imgOpen:this.$store.state.sourceMsg.box1_open },
      //     { id:0, num:150, img:this.$store.state.sourceMsg.box2, imgOpen:this.$store.state.sourceMsg.box2_open },
      //     { id:0, num:200, img:this.$store.state.sourceMsg.box3, imgOpen:this.$store.state.sourceMsg.box3_open },
      // ],
    }
  },
  created() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F6F6'
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      Telegram.WebApp.setHeaderColor('#121212')
    } else {
      Telegram.WebApp.setHeaderColor('#fff')
    }
    // 计算当前进度
    this.proNum()
    this.achievementListRight = achieveListRef.value
    this.getAchievementTask()
    let timer = setTimeout(() => {
      clearTimeout(timer)
      this.getAchievementTaskRight()
    }, 200)
  },
  mounted() {

  },
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  methods: {
    getAchievementTaskRight() {
      this.$api.achievementTask().then((res) => {
        if (res.code === 0) {
          this.achievementListRight = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            item.status = this.getStatus(item)
            item.url = item?.icon_url || imgs.bottle
            return item
          })
          achieveListRef.value = this.achievementListRight
          console.log(this.achievementListRight, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    handleBtnClickRight(item) {
      //['Go to', 'Receive', 'Finish'] 0 1 2
      const { status } = item
      switch (status) {
        case 0:
          const regex = /^[\/a-zA-Z]/
          const url = item?.payload?.jump_url
          let jumpurl = '/'
          if (regex.test(url)) {
            jumpurl = url
          }
          return this.$router.push({ path: jumpurl })
          break
        case 1:
          if (this.reqFlag) {
            return
          }
          this.reqFlag = true
          this.awardCoin = item?.award
          this.$api.getReward(item.id).then((res) => {
            if (res.code === 0) {
              this.isReceiveShow = true
              this.$nextTick(() => {
                this.getAchievementTaskRight()
                getUserInitInfo()
                this.reqFlag = false
              })
              return
            }
            this.reqFlag = false
          })
          break
        case 2:
          return
          break
      }
    },
    // 计算当前进度
    proNum() {
      let num = 0
      this.dailyList.map((item) => {
        if (item.status) {
          num = num + item.coin
        }
      })
      this.taskNum = num
    },
    changeTab(index) {
      this.activeTab = index
    },
    Receive() {
      this.show = true
    },
    getAchievementTask() {
      this.$api.getDailyTasks().then((res) => {
        if (res.code === 0) {
          this.dailyRemoteList = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            item.status = this.getStatus(item)
            item.url = item?.icon_url || '/imgs/task/Tg.png'
            return item
          })
          console.log(this.dailyRemoteList, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    MinCount(n, o) {
      return Math.min(n, o)
    },
    getStatus(item) {
      //['Go to', 'Receive', 'Finish','Check'] 0 1 2 3
      if (!item?.target?.is_completion_target) {
        return 0
      }
      if (
        item?.target?.is_completion_target &&
        !item?.target?.is_reward_collection
      ) {
        return 1
      }
      if (
        item?.target?.is_completion_target &&
        item?.target?.is_reward_collection
      ) {
        return 2
      }
    },
    async handleBtnclick2(item) {
      console.log(item, 777);

      if (this.reqFlag || item.status === 2) {
        return;
      }
      this.reqFlag = true
      let res;
      const userId = this.$g.getStorage('userId')
      if (
        (item?.target?.type == 4 || item?.url?.includes("invite")) &&
        item.status === 0
      ) {
        this.reqFlag = false
        return this.$g.invite(user.id);
      }
      if (item?.target?.type == 9) {
        await $pay.connect()
        this.reqFlag = false
      }
      if (item.payload || item?.target?.type === 8) {
        res = await getCurrentTaskStatus(item?.target?.type, userId, item.payload?.id);
        console.log(res, 'res->>>>>>');

      } else if (item.status === 0) {
        item.status = 3;
        console.log(item.status, 'item.status');
      }
      if (res === "link") {
        item.status = 3;
        console.log(item.status, 888888);
      }
      if (item.status === 1 || res === "reward") {
        const result = await this.$api.getReward(item?.id)
        if (result.code === 0 && result.data[0].number) {
          this.awardCoin = result.data[0].number
          showToast('Receive success')
          this.$api.getReward(id).then((res) => {
            if (res.code === 0) {
              this.isReceiveShow = true
              this.$nextTick(() => {
                this.getAchievementTask()
                getUserInitInfo()
                this.reqFlag = false
              })
              return
            }
          }).catch(() => {
            this.reqFlag = false
          })
        }
      }
      if (res === "link") {
        if (item.payload.jump_url.includes('t.me')) {
          window.Telegram.WebApp.openTelegramLink(item.payload.jump_url);
        } else {
          window.Telegram.WebApp.openLink(item.payload.jump_url);
        }
      }
      this.reqFlag = false
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  // height: 100vh;
  // background-color: #B2EFF9;
}

.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  /* height: 4rem; */
  height: 2rem;
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color) !important;
  width: 100%;

  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.tab {
  width: 90%;
  margin: 0 auto;
  margin-top: 3.5rem;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-around;

  div {
    height: 1.4rem;
    width: 6rem;
    color: #8698b7;
  }
}

.Progress {
  width: 90%;
  margin: 0 auto;
  /* padding-top: 6rem; */
  padding-top: 4rem;

  .proBg {
    height: 3rem;
    background-color: #ffedd5;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    .proLine {
      background-color: #aee6f1;
      position: relative;
      width: 90%;
      margin: 0 auto;
      height: 0.5rem;
      border-radius: 0.5rem;

      .nowLine {
        background-color: #39cce9;
        height: 0.5rem;
        border-radius: 0.5rem;
      }

      .proStart {
        left: -0.6rem;
        bottom: 1.9rem;
        width: 2.55rem;
        height: 2.95rem;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;

        div {
          position: absolute;
          bottom: 0.1rem;
          left: 0.7rem;
          font-size: 0.6rem;
          color: #ffffff;
        }
      }

      .boxItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 0.6rem;
        color: #8698b7;

        img {
          width: 1.75rem;
          height: 1.55rem;
        }
      }

      .box1 {
        top: -1rem;
        left: 3.35rem;
      }

      .box2 {
        top: -1rem;
        left: 6.5rem;
      }

      .box3 {
        top: -1rem;
        left: 9.65rem;
      }

      .box4 {
        top: -1rem;
        left: 12.7rem;
      }
    }
  }
}

.timeTop {
  width: 100%;
  margin: 0 auto;
  padding-top: 5rem;

  .proBg {
    background-color: #ffedd5;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0.5rem;

    .timeTip {
      font-size: 0.9rem;
      color: #8698b7;
    }

    .goTime {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }
  }
}

.list {
  width: 90%;
  margin: 0 auto;
  /* padding-top: 1rem; */
  // padding-top: 4rem;
  padding-bottom: 4rem;

  .listItem {
    background: var(--tg-theme-bg-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .itemLeft {
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .itemImg {
      width: 2.6rem;
      height: 2.6rem;
      margin-right: 0.5rem;
    }

    .itemStatus {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 2.8rem;

      .itemCoin {
        display: flex;
        align-items: center;
        height: 1rem;
        background: #daf6fa;
        color: var(--tg-theme-bg-color) !important;
        border-radius: 0.5rem;
        font-size: 0.6rem;
        width: 3rem;

        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.2rem;
        }
      }

      .itemSta {
        display: flex;
        flex-direction: column;
        align-items: center;

        .doTask {
          background-color: #39cce9;
          color: #ffffff;
          font-size: 0.7rem;
          width: 3.35rem;
          height: 1.4rem;
          line-height: 1.4rem;
          border-radius: 0.5rem;
        }

        img {
          width: 1rem;
          height: 0.75rem;
        }
      }
    }

    .itemTitle {
      display: flex;
      flex-direction: column;
      color: var(--tg-theme-text-color) !important;
      justify-content: space-around;
      font-size: 0.7rem;
      text-align: start;
      font-weight: bold;
    }
  }
}

.listItem {
  background: var(--tg-theme-bg-color);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .itemLeft {
    display: flex;

    .itemImg {
      display: flex;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      background: #b2eff9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;

      img {
        width: 1.55rem;
        height: 2.1rem;
      }
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 0.7rem;
      text-align: start;

      .itemSend {
        font-weight: bold;
      }

      .itemWidth {
        width: 7.5rem;
        height: 0.8rem;
        border-radius: 1rem;
        background: #eaf9fc;
        border: 1px solid #39cce9;
        position: relative;

        .procWidth {
          width: 3rem;
          height: 0.8rem;
          border-radius: 1rem;
          background: #39cce9;
        }

        .proc {
          font-size: 0.6rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .itemRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 2.8rem;

    .itemCoin {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1rem;
      background: #daf6fa;
      border-radius: 0.5rem;
      font-size: 0.7rem;
      width: 3rem;
      font-weight: bold;

      img {
        width: 1rem;
        height: 1rem;
      }
    }

    .receive {
      background: #39cce9;
      width: 3.8rem;
      height: 1.5rem;
      border-radius: 0.5rem;
      color: white;
      font-size: 0.8rem;
      line-height: 1.5rem;
    }

    .finish {
      color: #8698b7;
      background: #dee3ed;
    }
  }
}

.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
</style>
