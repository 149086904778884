<template>
  <start-page v-if="showStartPage" :step="startStep" :step1="startStep1" />
  <update-page v-if="showUpdatePage" />
  <RegisterPage v-if="showRegisterPage" @success="success" />
  <div id="rootContent" v-if="showPage" @touchmove="moveEvent" :class="[
    'root_content',
    pageSizeRate > 0.65 && winHeight < 600 && 'small_window',
    pageSizeRate > 0.65 && winHeight > 600 && 'pad_window',
    pageSizeRate < 0.65 && 'phone_window',
  ]">
    <div class="root_main">
      <router-view />
      <TabBar v-if="!$route.meta.hideTabBar"></TabBar>
    </div>
  </div>
</template>
<script>
import Vconsole from "vconsole";
import TabBar from "./components/tabBar.vue";
import StartPage from "./components/start.vue";
import UpdatePage from "./components/update.vue";
import RegisterPage from "./views/Register/register.vue";
import loadingSourceMsg from "./resource/index";
import {
  commonKeyInit,
  bagTotalInfo,
  bagUserInfo,
  getUserTotalInfo,
  tgInitData,
  goodsListRef,
} from "./config/common/total";
import { getTgInitData, commit } from "@/config/common/dic";
import { showToast } from "vant";
const overflow = 100;
document.body.style.overflowY = "hidden";
document.body.style.marginTop = `${overflow}px`;
document.body.style.height = window.innerHeight + overflow + "px";
document.body.style.paddingBottom = `${overflow}px`;
window.scrollTo(0, overflow);

export default {
  components: { StartPage, UpdatePage, TabBar, RegisterPage },
  data() {
    return {
      loading: false,
      winHeight: 0,
      pageSizeRate: 1,
      startStep: 0,
      startStep1: 100,
      showStartPage: true,
      showUpdatePage: false,
      showRegisterPage: false,
      showPage: false,
      isRegister: false,
      ts: 0,
      showTab: localStorage.getItem("showTab"),
      initData: "",
    };
  },
  watch: {
    "$route.query"(val) {
      try {
        if (val?.startapp == "testModel") {
          let vconsole = new Vconsole();
        }
      } catch (error) {
        console.error(error, "error->>>>>");
      }
    },
  },
  created() {
    // commit("Entry_loading");
    // 控制头部颜色
    this.$g.init("#ACECF9");
    let winWidth = window.innerWidth;
    let winHeight = window.innerHeight;
    this.winHeight = winHeight;
    this.pageSizeRate = winWidth / winHeight;
    // 小程序自动展开
    this.$g.fullScreen();
  },
  async mounted() {
    let html = document.getElementsByTagName("html")[0];
    let defaultWidth = 375;
    let defaultFontSize = 20;
    let currentWidth = window.innerWidth;
    let fontSize = defaultFontSize * (currentWidth / defaultWidth);
    html.style.fontSize = fontSize + "px";
    window.onresize = function () {
      // 当窗口比例改变时，计算字体大小
      let currentWidth = window.innerWidth;
      let fontSize = defaultFontSize * (currentWidth / defaultWidth);
      html.style.fontSize = fontSize + "px";
    };
    try {
      // 更新白名单
      this.loadingSourceEvent();
      this.init();
      let isShowUpdatePage = await this.$g.isShowUpdatePage();
      this.showUpdatePage = isShowUpdatePage;
    } catch (error) {
      this.$g.toast(error);
    }
  },
  methods: {
    async init() {
      try {
        Telegram.WebApp.ready();
        Telegram.WebApp.expand();
        let initData = window.Telegram.WebApp.initData;
        console.log(this.$config.VITE_MODEL, initData, "->>>>>>>>>>>>>>");
        if (this.$config.VITE_MODEL == "development" && !initData) {
          initData =
            "user=%7B%22id%22%3A6758985856%2C%22first_name%22%3A%22Less%20Enter%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Matinssjjh%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=4999428696029987043&chat_type=private&start_param=i_1438989232&auth_date=1717673316&hash=0a16df82c32000a73113e5c411d9d72420e2565799d9cc1c055c928bb8e5afd7";
          // initData =
          // 'user=%7B%22id%22%3A6654204999%2C%22first_name%22%3A%22Berna%22%2C%22last_name%22%3A%22Wolfe%22%2C%22username%22%3A%22Zy15635%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=2840042185398305678&chat_type=private&auth_date=1723444579&hash=b373b97515b24363a1d3113f7e2e5e0a801690fcd18b9d256f438dbad52e4b66'
          // initData =
          // 'user=%7B%22id%22%3A6353597026%2C%22first_name%22%3A%22fly%22%2C%22last_name%22%3A%22big%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-3887136870701614613&chat_type=private&auth_date=1725604159&hash=a4a6da0375341da63a292c0b4b4315e2e43a7028ab2d586c48167904669cb85f'
        }
        tgInitData.value = initData;
        this.initData = decodeURIComponent(initData);
        console.log("99999999999", this.initData);
        this.getPid();
        let timer = setTimeout(() => {
          clearTimeout(timer);
          this.getBagInfo();
        }, 500);
        console.log(window.Telegram.WebApp.colorScheme, '(window.Telegram.WebApp.colorScheme->>.');
        if (window.Telegram.WebApp.colorScheme === 'dark') {
          document.documentElement.style.setProperty('--tg-theme-bg-color', '#121212');
          document.documentElement.style.setProperty('--tg-theme-text-color', '#ffffff');
          document.documentElement.style.setProperty('--tg-theme-hint-color', '#333333');
          this.$g.init("#121212");
        } else {
          document.documentElement.style.setProperty('--tg-theme-bg-color', '#ffffff');
          document.documentElement.style.setProperty('--tg-theme-text-color', '#000000');
          document.documentElement.style.setProperty('--tg-theme-hint-color', '#f7f6f6');
          this.$g.init("#ACECF9");
        }

      } catch (error) {
        console.log(error, "error");
      }
    },
    // 注册成功
    async success() {
      this.startStep = 0;
      this.startStep1 = 100;
      try {
        this.showStartPage = true;
        this.showRegisterPage = false;
        this.init();
      } catch (error) {
        this.$g.toast(error);
      }
    },
    moveEvent() {
      return false;
    },
    onRefresh() {
      this.loading = false;
    },
    getPid() {
      var iDatas = this.initData.split("&");
      let user = {};
      try {
        // 获取电报对象中的user字段，包含userId
        iDatas.forEach((x, index) => {
          iDatas[index] = x.split("=");
          if (iDatas[index][0] == "user") {
            user = JSON.parse(iDatas[index][1]);
            const preid = this.$g.getStorage("userId");
            try {
              if (preid && preid != user.id) {
                this.$g.rmStorage("userId");
                this.$g.rmStorage("token");
                this.$g.rmStorage("userInfo");
              }
            } catch (error) {
              console.error(error, "->>>error");
            }
            this.$g.setStorage("userId", user.id);
            commonKeyInit(user?.id);
          }
        });
      } catch (error) {
        console.error(error, "->>>error");
      }
      console.log(user, "user==>>>>>>>>>>>>>>>>>>");

      if (JSON.stringify(user) != "{}") {
        console.log("user==", user);
        let data = {
          tgid: user.id,
        };

        const successFn = async () => {
          this.startStep = 100;
          this.startStep1 = 0;
          // let timer = setTimeout(() => {
          //   clearTimeout(timer);
          //   this.showStartPage = false;
          //   this.showPage = true;
          //   this.$api.editUsername({
          //     username: getTgInitData("username"),
          //   });
          //   this.$router.push("/home");
          // }, 0);
          getUserTotalInfo();
          let timer = setTimeout(() => {
            clearTimeout(timer);
            this.showStartPage = false;
            this.showPage = true;
            this.$router.push("/home");
            this.$api.editUsername({
              username: getTgInitData("username"),
            });
          }, 150);
        };
        // 调用登录接口
        this.$api
          .login(data)
          .then((res) => {
            console.log(res);
            if (res.code === 0) {
              this.$g.setStorage("userInfo", JSON.stringify(res.data));
              this.$g.setStorage("token", res.data.auth_token);
              successFn();
            } else if (res.code === 100101 || res.code === 10000) {
              this.startStep = 100;
              this.startStep1 = 0;
              let timer = setTimeout(() => {
                clearTimeout(timer);
                this.showStartPage = false;
                this.showRegisterPage = true;
              }, 300);
            } else {
              showToast("login error");
            }
          })
          .catch((e) => {
            //
          });
      }
    },
    handleWheel(event) {
      const container = this.$refs.rootContent;
      // console.log(event.target , container)
      // 判断滚动事件发生的位置，如果不是在容器内部，则直接返回，禁用滚动
      if (event.target !== container) return false;
    },
    async loadingSourceEvent() {
      try {
        let loadResourceKey = "first";
        let loadResourceKey2 = "init2";
        let initLoadResourceKey = "init";
        let loadingSourceArr = loadingSourceMsg[loadResourceKey];
        let loadingSourceArr2 = loadingSourceMsg[loadResourceKey2];
        console.log(loadingSourceArr, 'loadingSourceArr');
        let initLoadingSourceArr = loadingSourceMsg[initLoadResourceKey];
        let totalItems = initLoadingSourceArr.length;
        let itemAdd = 60 / totalItems;
        let reqArr = [];
        let progressSteps = [];
        // Prepare requests and accumulate step values
        for (let i = 0; i < totalItems; i++) {
          reqArr.push(
            this.$g.loadImg(initLoadingSourceArr[i].name, initLoadingSourceArr[i].path)
          );
          progressSteps.push(itemAdd);
        }

        // Wait for all images to load
        const res = await Promise.all(reqArr);

        // Update the steps once
        this.startStep += progressSteps.reduce((sum, item) => sum + item, 0);
        this.startStep1 -= progressSteps.reduce((sum, item) => sum + item, 0);

        // Process the loaded resources
        let resourceObj = {};
        res.forEach(item => {
          resourceObj[item.key] = item.path;
        });
        this.$store.dispatch("saveResource", resourceObj);
        console.log(+new Date(), "2222222222222222===>===>===>===>===>===>");
        let timer2 = setTimeout(async () => {
          clearTimeout(timer2)
          let otherResourceObj = {}
          for (const item of loadingSourceArr2) {
            console.log(777);
            const res = await this.$g.loadImg(item.name, item.path)
            otherResourceObj[res.key] = res.path
          }
          console.log(+new Date(), "===>===>===>===>===>===>");
          this.$store.dispatch("updateResource", otherResourceObj);
        }, 0);
        let timer = setTimeout(async () => {
          clearTimeout(timer)
          let otherResourceObj = {}
          for (const item of loadingSourceArr) {
            console.log(777);
            const res = await this.$g.loadImg(item.name, item.path)
            otherResourceObj[res.key] = res.path
          }
          console.log(+new Date(), "===>===>===>===>===>===>");
          this.$store.dispatch("updateResource", otherResourceObj);
        }, 500);
      } catch (error) {
        console.error("Resource load fail", error);
        throw new Error("Resource load fail");
      }
    },
    getBagInfo() {
      this.$api.getItem().then((res) => {
        if (res.code === 0) {
          bagTotalInfo.value = res?.data;
          console.log(res?.data, "totalInfo");
        }
      });
      this.$api.getUserBag().then((res) => {
        if (res.code === 0) {
          bagUserInfo.value = res?.data;
          console.log(res?.data);
        }
      });
      this.$api.getGoodsList().then((res) => {
        if (res.code === 0) {
          goodsListRef.value = res?.data;
          console.log(res?.data, "shop-list");
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import url("./config/scss/global.scss");
@import url("./scss/index.scss");

.root_main {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1;
  overflow-y: scroll;
}
</style>
